<script setup lang="ts">
const { signIn } = useAuth()
</script>

<template>
  <BaseButton v-bind="$attrs" color="danger" @click="signIn('monitax')">
    <Icon name="tabler:login-2" class="size-5" />
    <span>Login With SSO Inatax</span></BaseButton
  >
</template>

<style scoped></style>
